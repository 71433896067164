import React, { useEffect, useRef } from 'react';
import './ProgressBar.css';
import { getCategoryIcon } from '../utils/iconUtils';

const ProgressBar = ({ currentStep, totalSteps, stepName, isSelectionMade, onNextClick, onPreviousClick, totalPrice, categories, activeCategory, onCategoryChange }) => {
  const progressRef = useRef(null);

  useEffect(() => {
    const checkOverflow = () => {
      const element = progressRef.current;
      if (element) {
        const isOverflowing = element.scrollWidth > element.clientWidth;
        element.classList.toggle('overflow', isOverflowing);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, []);

  return (
    <div className="progress-bar-container">
      {currentStep === 2 && (
        <div className="interlocking-progress" ref={progressRef}>
          <div className="progress-segments-wrapper">
            {categories.map((category) => {
              const isCompleted = categories.findIndex(c => c.id === activeCategory) > categories.findIndex(c => c.id === category.id);
              const isActive = category.id === activeCategory;
              return (
                <div 
                  key={category.id}
                  className={`progress-segment ${isCompleted ? 'completed' : ''} ${isActive ? 'active' : ''}`}
                  onClick={() => onCategoryChange(category.id)}
                >
                  <div className="segment-content">
                    <img 
                      src={getCategoryIcon(category.id, isCompleted || isActive)} 
                      alt={category.display} 
                      className="segment-icon"
                    />
                    <span className="segment-name">{category.display}</span>
                  </div>
                  <div className="segment-arrow"></div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className="progress-content">
        <button 
          className="nav-button previous"
          onClick={onPreviousClick}
          disabled={currentStep === 1}
        >
          <span className="nav-icon">&#8592;</span> Previous
        </button>
        <div className="step-info">
          <span className="step-number">Step {currentStep}/{totalSteps}</span>
          <span className="step-name">{stepName}</span>
        </div>
        <div className="total-price">
          Build Total: <span className="price-value">${totalPrice.toFixed(2)}</span>
        </div> 
        <button 
          className="nav-button next"
          onClick={onNextClick}
          disabled={!isSelectionMade}
        >
          {currentStep === totalSteps ? 'Finish' : 'Next'} <span className="nav-icon">&#8594;</span>
        </button>
      </div>
    </div>
  );
};

export default ProgressBar;