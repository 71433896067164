import framesBlack from '../assets/frames_black.png';
import framesWhite from '../assets/frames_white.png';
import propellerBlack from '../assets/propeller_black.png';
import propellerWhite from '../assets/propeller_white.png';
import batteryBlack from '../assets/battery_black.png';
import batteryWhite from '../assets/battery_white.png';
import fcBlack from '../assets/fc_black.png';
import fcWhite from '../assets/fc_white.png';
import escBlack from '../assets/esc_black.png';
import escWhite from '../assets/esc_white.png';
import vtxBlack from '../assets/vtx_black.png';
import vtxWhite from '../assets/vtx_white.png';
import camBlack from '../assets/cam_black.png';
import camWhite from '../assets/cam_white.png';
import signalBlack from '../assets/signal_black.png';
import signalWhite from '../assets/signal_white.png';
import motorBlack from '../assets/motor_black.png';
import motorWhite from '../assets/motor_white.png';

export const getCategoryIcon = (categoryId, isActive) => {
  const icons = {
    Frames: isActive ? framesWhite : framesBlack,
    Propellers: isActive ? propellerWhite : propellerBlack,
    Motors: isActive ? motorWhite : motorBlack,
    Batteries: isActive ? batteryWhite : batteryBlack,
    FlightControllers: isActive ? fcWhite : fcBlack,
    ESCs: isActive ? escWhite : escBlack,
    VideoTransmitters: isActive ? vtxWhite : vtxBlack,
    FPVCameras: isActive ? camWhite : camBlack,
    Receivers: isActive ? signalWhite : signalBlack
  };
  return icons[categoryId] || (isActive ? vtxWhite : vtxBlack); // Default to VTX icon if category not found
};