import React from 'react';
import './ComponentModal.css';

const ComponentModal = ({ component, onClose, onSelect }) => {
  const tagColors = {
    Size: '#FF6B6B',
    'Motor Mount': '#4ECDC4',
    'Stack Mount': '#45B7D1',
    'Max Prop Size': '#FFA07A',
    'Camera Mount': '#98D8C8',
    'Arm Thickness': '#F7DC6F',
    'Weight Class': '#AED6F1',
    Material: '#D7BDE2',
    Diameter: '#FF9FF3',
    Pitch: '#FECA57',
    Bore: '#54A0FF',
    Rotation: '#5CD859',
    'Blade Count': '#FF6B6B',
    'Stator Size': '#4ECDC4',
    'Shaft Diameter': '#45B7D1',
    'Mounting Pattern': '#FFA07A',
    'KV Rating': '#98D8C8',
    'Prop Compatibility': '#F7DC6F',
    Voltage: '#AED6F1',
    Capacity: '#D7BDE2',
    'Discharge Rate': '#FF9FF3',
    Connector: '#FECA57',
    'Form Factor': '#54A0FF',
    Processor: '#5CD859',
    Firmware: '#FF6B6B',
    Gyro: '#4ECDC4',
    'UART Count': '#45B7D1',
    'Current Rating': '#FFA07A',
    Protocol: '#98D8C8',
    Frequency: '#F7DC6F',
    'Power Output': '#AED6F1',
    'Video Format': '#D7BDE2',
    'Antenna Connector': '#FF9FF3',
    'Smart Audio': '#FECA57',
    'Sensor Size': '#54A0FF',
    Resolution: '#5CD859',
    Lens: '#FF6B6B',
    FOV: '#4ECDC4',
    Telemetry: '#45B7D1',
    'Antenna Type': '#FFA07A'
  };

  const compatibilityTags = component.compatibilityTags || {};
  const hasCompatibilityTags = Object.values(compatibilityTags).some(values => 
    values && (Array.isArray(values) ? values.length > 0 : true)
  );

  const renderCompatibilityTags = () => {
    return Object.entries(compatibilityTags)
      .filter(([_, values]) => values && (Array.isArray(values) ? values.length > 0 : true))
      .map(([category, values]) => {
        const valueArray = Array.isArray(values) ? values : [values];
        return (
          <div key={category} className="compatibility-category">
            <h4>{category}</h4>
            <div className="tag-container">
              {valueArray.map((value, index) => (
                <span 
                  key={`${category}-${index}`} 
                  className="compatibility-tag"
                  style={{backgroundColor: tagColors[category] || '#999'}}
                >
                  {value}
                </span>
              ))}
            </div>
          </div>
        );
      });
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <div className="modal-header">
          <h2>{component.name}</h2>
          <button className="close-button" onClick={onClose}>&times;</button>
        </div>
        <div className="modal-body">
          {component.image && <img src={component.image} alt={component.name} className="modal-image" />}
          {component.shortDescription && <p>{component.shortDescription}</p>}
          {component.fullDescription && <p>{component.fullDescription}</p>}
          
          {component.specifications && (
            <div className="specifications">
              <h3>Specifications</h3>
              <ul>
                {Object.entries(component.specifications).map(([key, value]) => (
                  <li key={key}><strong>{key}:</strong> {value}</li>
                ))}
              </ul>
            </div>
          )}
          
          {hasCompatibilityTags && (
            <div className="compatibility-tags">
              <h3 className="centered">Compatibility</h3>
              <div className="tag-container">
                {renderCompatibilityTags()}
              </div>
            </div>
          )}
          
          <p><strong>Price:</strong> ${component.price}</p>
          
          {component.links && Object.keys(component.links).length > 0 && (
            <div className="purchase-links">
              {Object.entries(component.links).map(([retailer, linkData]) => (
                <div key={retailer} className="purchase-link-item">
                  <img src={`/retailer-icons/${retailer.toLowerCase()}.png`} alt={retailer} className="retailer-icon" />
                  <span className="retailer-name">{retailer}</span>
                  <a href={linkData.url} target="_blank" rel="noopener noreferrer" className="buy-button">BUY</a>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="modal-footer">
          <button onClick={() => onSelect(component)}>Select</button>
        </div>
      </div>
    </div>
  );
};

export default ComponentModal;