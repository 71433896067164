import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'https://backend-fpv-partpicker.onrender.com/api';

export const getComponents = async (category, compatibilityTags = []) => {
  try {
    const response = await axios.get(`${API_URL}/components/${category}`, {
      params: { compatibilityTags: compatibilityTags.join(',') }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching components:', error);
    return [];
  }
};  