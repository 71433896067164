import React from 'react';
import './InfoModal.css';
import inch_3 from '../assets/3inch.png';
import inch_4 from '../assets/4inch.png';
import inch_5 from '../assets/5inch.png';
import inch_6 from '../assets/6inch.png';
import material from '../assets/material.png';
import layout from '../assets/layout.png';
import arm from '../assets/arm.png';

const InfoModal = ({ isOpen, onClose, title }) => {
  if (!isOpen) return null;

  return (
    <div className="info-modal-overlay" onClick={onClose}>
      <div className="info-modal-content" onClick={e => e.stopPropagation()}>
        <div className="info-modal-header">
          <h2>{title}</h2>
          <button className="close-button" onClick={onClose}>&times;</button>
        </div>
        <div className="info-modal-body">
          <div className="important-note">
            <strong>Key Consideration:</strong> Frame size greatly influences your drone's performance and capabilities.
          </div>
          <div className="size-comparison">
            <h3>Frame Size Comparison</h3>
            <div className="frame-sizes">
              {[3, 4, 5, 6].map(size => (
                <div key={size} className="frame-size-item">
                  <div className="image-container">
                    <img 
                      src={size === 3 ? inch_3 : size === 4 ? inch_4 : size === 5 ? inch_5 : inch_6} 
                      alt={`${size} inch frame`} 
                      style={{width: `${(size/6)*85}%`}} // Scale image based on size, max at 85%
                    />
                  </div>
                  <div className="frame-info">
                    <p>{size}" Frame</p>
                    <span className="size-description">{getSizeDescription(size)}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="other-factors">
            <h3>Other Important Factors</h3>
            <div className="factors-grid">
              <div className="factor-item">
                <img src={material} alt="Material" className="factor-icon" />
                <h4>Material</h4>
                <p>Usually carbon fiber. Affects strength and weight.</p>
              </div>
              <div className="factor-item">
                <img src={layout} alt="Layout" className="factor-icon" />
                <h4>Layout</h4>
                <p>X, H, or True-X. Influences flight characteristics.</p>
              </div>
              <div className="factor-item">
                <img src={arm} alt="Arm Design" className="factor-icon" />
                <h4>Arm Design</h4>
                <p>Impacts durability and aerodynamics.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-buttons">
          <button onClick={onClose}>Got it!</button>
        </div>
      </div>
    </div>
  );
};

function getSizeDescription(size) {
  switch(size) {
    case 3: return "Compact, agile, often used for indoor or tight space flying";
    case 4: return "Balanced size, good for both agility and stability";
    case 5: return "Popular all-rounder, great for freestyle and racing";
    case 6: return "Larger, more stable, often used for long-range or heavy payload";
    default: return "";
  }
}

export default InfoModal;