import React, { useState, useEffect, useCallback } from 'react';
import './SelectComponents.css';
import ComponentCard from './ComponentCard.jsx';
import ComponentModal from './ComponentModal.jsx';
import SelectedPartsModal from './SelectedPartsModal.jsx';
import InfoModal from './InfoModal.jsx';
import * as api from '../api/api.js';

const SelectComponents = ({ setComponents, initialComponents, activeCategory, onCategoryChange, categories, onNext }) => {
  const [componentsList, setComponentsList] = useState([]);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [selectedComponents, setSelectedComponents] = useState(initialComponents || {});
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showSelectedPartsModal, setShowSelectedPartsModal] = useState(false);
  const [neverShowInfoModal, setNeverShowInfoModal] = useState(false);

  useEffect(() => {
    const neverShow = localStorage.getItem('neverShowFramesInfo') === 'true';
    setNeverShowInfoModal(neverShow);
  }, []);

  const fetchComponents = useCallback(async () => {
    const components = await api.getComponents(activeCategory);
    setComponentsList(components);
  }, [activeCategory]);

  useEffect(() => {
    fetchComponents();
    if (activeCategory === 'Frames' && !neverShowInfoModal) {
      setShowInfoModal(true);
    }
  }, [fetchComponents, activeCategory, neverShowInfoModal]);

  useEffect(() => {
    setComponents(selectedComponents);
  }, [selectedComponents, setComponents]);

  const handleComponentSelect = (component) => {
    if (component._id !== selectedComponents[activeCategory]?._id) {
      setSelectedComponents(prev => ({
        ...prev,
        [activeCategory]: component
      }));
      setComponents(prev => ({
        ...prev,
        [activeCategory]: component
      }));

      const timer = setTimeout(() => {
        const currentIndex = categories.findIndex(cat => cat.id === activeCategory);
        if (currentIndex < categories.length - 1) {
          onCategoryChange(categories[currentIndex + 1].id);
        } else {
          onNext(); // Move to the next main section (Review page)
        }
      }, 500);

      return () => clearTimeout(timer);
    }
  };

  const openComponentModal = (component) => {
    setSelectedComponent(component);
  };

  const closeComponentModal = () => {
    setSelectedComponent(null);
  };

  const toggleInfoModal = () => {
    setShowInfoModal(!showInfoModal);
  };

  const handleNeverShowAgain = () => {
    setNeverShowInfoModal(true);
    setShowInfoModal(false);
    localStorage.setItem('neverShowFramesInfo', 'true');
  };

  const renderInfoContent = () => (
    <>
      <p>The frame is the backbone of your FPV drone, crucial for determining its performance and capabilities. When choosing a frame, consider these key factors:</p>
      <div className="info-grid">
        <div className="info-item">
          <h3>Size</h3>
          <p>Measured diagonally in inches (e.g., 3", 5", 7"). Affects overall drone size, weight, and prop compatibility.</p>
        </div>
        <div className="info-item">
          <h3>Material</h3>
          <p>Usually carbon fiber for strength and lightness. Thickness (e.g., 4mm, 5mm) affects durability and weight.</p>
        </div>
        <div className="info-item">
          <h3>Layout</h3>
          <p>X, H, or True-X configurations offer different flight characteristics and component placement options.</p>
        </div>
        <div className="info-item">
          <h3>Arm Design</h3>
          <p>Affects durability, aerodynamics, and ease of replacement. Consider thickness and shape.</p>
        </div>
      </div>
      <div className="compatibility-section">
        <h3>Compatibility Considerations</h3>
        <ul>
          <li><strong>Drone Type:</strong> Ensure the frame suits your intended use (Cinewhoop, Freestyle, Racing, etc.).</li>
          <li><strong>Motor Mounts:</strong> Check the mounting pattern (e.g., 16x16mm, 25x25mm) matches your chosen motors.</li>
          <li><strong>Stack Mount:</strong> Verify the mounting holes (e.g., 20x20mm, 30.5x30.5mm) fit your flight controller and ESC.</li>
          <li><strong>Camera Mount:</strong> Ensure compatibility with your chosen FPV camera size (e.g., 19mm, 21mm).</li>
          <li><strong>VTX Mount:</strong> Check for dedicated video transmitter mounting options if needed.</li>
          <li><strong>Battery Mount:</strong> Consider how and where the battery will be secured.</li>
        </ul>
      </div>
      <p className="final-note">Choose based on your flying style, skill level, and intended use. A well-chosen frame sets the foundation for a great flying experience!</p>
    </>
  );

  return (
    <div className="select-components-container">
      <div className="select-components-header">
        <h2>{activeCategory}</h2>
        {activeCategory === 'Frames' && (
          <button className="learn-more-btn" onClick={() => setShowInfoModal(true)}>Learn More</button>
        )}
      </div>
      <div className="components-content">
        <div className="components-grid">
          {componentsList.map(component => (
            <ComponentCard
              key={component._id}
              component={component}
              onSelect={handleComponentSelect}
              onMoreInfo={() => openComponentModal(component)}
              isSelected={selectedComponents[activeCategory]?._id === component._id}
            />
          ))}
        </div>
      </div>
      {selectedComponent && (
        <ComponentModal
          component={selectedComponent}
          onClose={closeComponentModal}
          onSelect={handleComponentSelect}
        />
      )}
      <InfoModal
        isOpen={showInfoModal}
        onClose={() => setShowInfoModal(false)}
        title="Understanding Frames"
        content={renderInfoContent()}
      />
      <SelectedPartsModal components={selectedComponents} />
    </div>
  );
};

export default SelectComponents;