import React from 'react';
import './ComponentCard.css';

const ComponentCard = ({ component, onSelect, onMoreInfo, isSelected }) => {
  const handleSelect = (e) => {
    e.stopPropagation();
    onSelect(component);
  };

  const handleMoreInfo = (e) => {
    e.stopPropagation();
    onMoreInfo(component);
  };

  const linkCount = component.links ? Object.keys(component.links).length : 0;

  // Truncate the short description
  const truncateDescription = (text, maxLength = 100) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + '...';
  };

  return (
    <div className={`component-card ${isSelected ? 'selected' : ''}`} onClick={handleSelect}>
      <img src={component.image} alt={component.name} className="select-component-image" />
      <h3 title={component.name}>{component.name}</h3>
      <p className="short-description" title={component.shortDescription}>
        {truncateDescription(component.shortDescription)}
      </p>
      <p className="price">Price: ${component.price.toFixed(2)}</p>
      {linkCount > 0 && (
        <p className="purchase-options">
          <span className="green-circle"></span>
          {linkCount} purchase option{linkCount > 1 ? 's' : ''}
        </p>
      )}
      <div className="component-card-buttons">
        <button className="select-button" onClick={handleSelect}>Select</button>
        <button className="info-button" onClick={handleMoreInfo}>More Info</button>
      </div>
    </div>
  );
};

export default ComponentCard;