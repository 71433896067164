import React from 'react';
import './ReviewPage.css';

const ReviewPage = ({ components }) => {
  const totalPrice = Object.values(components).reduce((sum, component) => sum + (component.price || 0), 0);

  const renderLinks = (links) => {
    return Object.entries(links).map(([retailer, linkData]) => {
      const logoSrc = `/retailer-icons/${retailer.toLowerCase()}.png`;
      const url = typeof linkData === 'string' ? linkData : linkData.url;
      return (
        <a key={retailer} href={url} target="_blank" rel="noopener noreferrer" className="buy-link">
          <img src={logoSrc} alt={retailer} className="vendor-logo" onError={(e) => { e.target.onerror = null; e.target.src = '/retailer-icons/default.png'; }} />
          <span>Buy on {retailer}</span>
        </a>
      );
    });
  };

  return (
    <div className="review-page">
      <h2>Review Your Build</h2>
      <div className="component-list">
        {Object.entries(components).map(([category, component]) => (
          <div key={category} className="component-item">
            <div className="component-details">
              <img src={component.image} alt={component.name} className="review-component-image" />
              <div className="component-info">
                <h4>{component.name}</h4>
                <p>{component.shortDescription}</p>
                <p className="price2">${component.price.toFixed(2)}</p>
              </div>
            </div>
            <div className="buy-section">
              {component.links && renderLinks(component.links)}
            </div>
          </div>
        ))}
      </div>
      <div className="total-price">
        Total Price: ${totalPrice.toFixed(2)}
      </div>
    </div>
  );
};

export default ReviewPage;