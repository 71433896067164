import React, { useState, useEffect, useRef } from 'react';
import './SelectedPartsModal.css';

const SelectedPartsModal = ({ components }) => {
  const [isOpen, setIsOpen] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div 
      className={`selected-parts-modal ${isOpen ? 'open' : ''}`} 
      onMouseEnter={() => setIsOpen(true)}
      ref={modalRef}
    >
      <div className="modal-toggle" onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? '❯' : '❮'}
      </div>
      <div className="modal-content">
        <h2>Selected Components</h2>
        <div className="selected-parts-list">
          {Object.entries(components).map(([category, component]) => (
            component && (
              <div key={category} className="selected-part">
                <img src={component.image} alt={component.name} className="part-image" />
                <div className="part-info">
                  <h3>{component.name}</h3>
                  <p>{category}</p>
                  <p className="part-price">${component.price.toFixed(2)}</p>
                </div>
              </div>
            )
          ))}
        </div>
        <div className="total-price">
          Total: ${Object.values(components).reduce((sum, component) => sum + (component?.price || 0), 0).toFixed(2)}
        </div>
      </div>
    </div>
  );
};

export default SelectedPartsModal;