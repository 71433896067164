import React, { useState, useEffect } from 'react';
import './Landing.css';
import ChooseDroneType from './ChooseDroneType';
import SelectComponents from './SelectComponents';
import ReviewPage from './ReviewPage';

const Landing = ({ currentStep, setCurrentStep, activeCategory, setActiveCategory, totalPrice, setTotalPrice, categories }) => {
    const [selectedDrone, setSelectedDrone] = useState(null);
    const [components, setComponents] = useState({});

    const handleNext = () => {
        setCurrentStep(prevStep => prevStep + 1);
    };

    useEffect(() => {
        const newTotalPrice = Object.values(components).reduce((sum, component) => sum + (component.price || 0), 0);
        setTotalPrice(newTotalPrice);
    }, [components, setTotalPrice]);

    // Reset internal state when currentStep changes to 1
    useEffect(() => {
        if (currentStep === 1) {
            setSelectedDrone(null);
            setComponents({});
        }
    }, [currentStep]);

    const renderStepContent = () => {
        switch (currentStep) {
            case 1:
                return <ChooseDroneType 
                    selectedDrone={selectedDrone}
                    setSelectedDrone={setSelectedDrone}
                    onNext={handleNext}
                />;
            case 2:
                return <SelectComponents
                    selectedDrone={selectedDrone}
                    setComponents={setComponents}
                    initialComponents={components}
                    activeCategory={activeCategory}
                    onCategoryChange={setActiveCategory}
                    categories={categories}
                    onNext={handleNext}
                />;
            case 3:
                return <ReviewPage 
                    components={components}
                    onPrevious={() => setCurrentStep(2)}
                />;
            default:
                return null;
        }
    };

    return (
        <div className="landing-container">
            <div className="landing-content">
                {renderStepContent()}
            </div>
        </div>
    );
};

export default Landing;