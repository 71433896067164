import React from 'react';
import { Link } from 'react-router-dom';
import './About.css';
import firstEncounter from '../assets/first-encounter.jpg';
import learning from '../assets/learning.jpg';
import building from '../assets/building.jpg';
import community from '../assets/community.png';

const About = () => {
  return (
    <div className="about-container">
      <header className="about-header">
        <h1>Discover the Thrill of FPV Drones</h1>
        <p>Join us on an exhilarating journey through the world of First Person View flying</p>
      </header>

      <div className="quote-section">
        <blockquote>"Once you've tasted flight, you will forever walk the earth with your eyes turned skyward, for there you have been, and there you will always long to return."</blockquote>
        <p className="quote-author">- Leonardo da Vinci</p>
      </div>

      <section className="journey-section">
        <div className="journey-step">
          <img src={firstEncounter} alt="First FPV encounter" />
          <h2>The First Encounter</h2>
          <p>Remember the moment you first saw an FPV drone video? The rush, the freedom, the perspective that seemed almost unreal. That's where it all begins.</p>
        </div>

        <div className="journey-step">
          <img src={learning} alt="Learning to fly" />
          <h2>Learning to Fly</h2>
          <p>From your first hover to mastering acrobatic maneuvers, every flight is a step towards becoming one with your drone.</p>
        </div>

        <div className="journey-step">
          <img src={building} alt="Building your first drone" />
          <h2>Crafting Your Wings</h2>
          <p>The satisfaction of building your own FPV drone is unparalleled. It's not just a hobby; it's an art form.</p>
        </div>

        <div className="journey-step">
          <img src={community} alt="FPV community" />
          <h2>Joining the Community</h2>
          <p>Discover a world of passionate pilots, makers, and dreamers. In the FPV community, you're never flying solo.</p>
        </div>
      </section>

      <section className="mission-section">
        <h2>Our Mission</h2>
        <p>At DroneFPV Part Picker, we're dedicated to helping you build the perfect FPV setup. Whether you're a beginner or a seasoned pilot, we provide the tools and knowledge to elevate your flying experience.</p>
        <Link to="/" className="cta-button">Start Your Build</Link>
      </section>
    </div>
  );
};

export default About;