import React, { useEffect } from 'react';
import cinewhoop_landing from '../assets/cinewhoop_landing.jpg';
import freestyle_landing from '../assets/freestyle_landing.jpeg';
import racing_landing from '../assets/racing_landing.jpeg';
import longrange_landing from '../assets/longrange_landing.jpeg';
import micro_landing from '../assets/micro_landing.jpg';
import tinywhoop_landing from '../assets/tinywhoop_landing.jpeg';
import custom_landing from '../assets/custom_landing.jpg';

const drones = [
  {
    name: "Cinewhoop",
    description: "Ducted, typically 3-inch propellers, for smooth cinematic footage in tight spaces.",
    image: cinewhoop_landing,
    price: "From $299"
  },
  {
    name: "Freestyle",
    description: "Agile and durable, usually 5-inch props, for acrobatic flying and tricks.",
    image: freestyle_landing,
    price: "From $349"
  },
  {
    name: "Racing",
    description: "Lightweight and fast, optimized for speed and agility in competitive racing.",
    image: racing_landing,
    price: "From $399"
  },
  {
    name: "Long Range",
    description: "Efficient builds with larger batteries for extended flight times and distance.",
    image: longrange_landing,
    price: "From $449"
  },
  {
    name: "Micro/Toothpick",
    description: "Ultra-lightweight (often under 250g), for indoor or outdoor flying in confined spaces.",
    image: micro_landing,
    price: "From $199"
  },
  {
    name: "Tiny Whoop",
    description: "Very small (usually 65-75mm) ducted drones for indoor flying.",
    image: tinywhoop_landing,
    price: "From $99"
  },
  {
    name: "Custom / Start from Scratch",
    description: "Build your own custom drone from individual components.",
    image: custom_landing,
    price: "Varies"
  }
];

const ChooseDroneType = ({ selectedDrone, setSelectedDrone, onNext }) => {
    const handleDroneClick = (index) => {
        if (index !== selectedDrone) {
            setSelectedDrone(index);
            const timer = setTimeout(() => {
                onNext();
            }, 500);
            return () => clearTimeout(timer);
        }
    };

    return (
        <div className="drone-grid">
            {drones.map((drone, index) => (
                <div
                    key={index}
                    className={`drone-card ${selectedDrone === index ? 'selected' : ''}`}
                    onClick={() => handleDroneClick(index)}
                >
                    <img src={drone.image} alt={drone.name} className="drone-image" />
                    <div className="drone-info">
                        <h2>{drone.name}</h2>
                        <p className="drone-description">{drone.description}</p>
                        <p className="drone-price">{drone.price}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ChooseDroneType;